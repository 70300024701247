import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../components/shared-layout";
import StudentCard from "../components/student-card";
import useStores from '../hooks/use-stores';
import { observer } from 'mobx-react';
import { CircularProgress, MenuItem, NativeSelect, Select, Tab, Tabs } from '@material-ui/core';
import IESLineRace from '../components/charts/iesLineRace';
import IESRadar from '../components/charts/iesRadar';
import TabPanel from '../components/tab-panel';
import { PRIORITY_SUBJECT_1, PRIORITY_SUBJECT_2, useStyles } from '../constants/style';
import { useTranslation } from 'react-i18next';
import VMCard from '../components/collapsed-card';

function performanceTabProps(index: number, activeTab: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${activeTab ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const Dashboard = observer(() => {
  const { rootStore, studentStore, filterStore } = useStores();
  const { t } = useTranslation();
  const fieldStyle = "mt-4 ml-4 text-gray-500 font-light font-medium text-sm 2xl:text-md";
  const classes = useStyles();
  const [performanceTab, setPerformanceTab] = useState<number>(0);
  const [yearLevelTabs, setYearLevelTabs] = useState<any>();
  const [mapYearLevelToDomainData, setMapYearLevelToDomainData] = useState<any>();
  const [legend, setLegend] = useState<any>();
  const [selectedLegend, setSelectedLegend] = useState<any>();
  const [xAxis, setXAxis] = useState<any>();
  const [performanceData, setPerformanceData] = useState<any>();
  const [naplanLegend, setNaplanLegend] = useState<any>();
  const [naplanIndicator, setNaplanIndicator] = useState<any>();
  const [naplanPrepareData, setNaplanPrepareData] = useState<any>();
  const [naplanData, setNaplanData] = useState<any>();
  const [naplanByYearLegend, setNaplanByYearLegend] = useState<any>();
  const [naplanByYearPrepareData, setNaplanByYearPrepareData] = useState<any>();
  const [naplanByYearData, setNaplanByYearData] = useState<any>();
  const [naplanByYearLevelLegend, setNaplanByYearLevelLegend] = useState<any>();
  const [naplanByYearLevelPrepareData, setNaplanByYearLevelPrepareData] = useState<any>();
  const [naplanByYearLevelData, setNaplanByYearLevelData] = useState<any>();
  const [yearInput, setYearInput] = useState<number>(0);
  const [yearLevelInput, setYearLevelInput] = useState<number>(0);
  const [yearList, setYearList] = useState<any>();
  const [yearLevelList, setYearLevelList] = useState<any>();
  const [showAllLegend, setShowAllLegend] = useState<boolean>(false);
  const [initialLegend, setInitialLegend] = useState<boolean>(true);

  useEffect(() => {
    setPerformanceTab(0);
    if (studentStore.schoolDashboardDomainData.length == 0) {
      studentStore.getDashboardSchoolDomainMarkData()
        .then((res) => {
          // ==================== 
          // Stacked Line Chart
          // ====================
          if (res.length > 0) {
            let tempTabs = res.map(data => data.yearLevel)
              .sort(function (a, b) {
                return a - b;
              });
            setYearLevelTabs(tempTabs);

            let mapYearLevelToDomain = res
              .sort(function (a, b) {
                return a.yearLevel - b.yearLevel;
              });
            setMapYearLevelToDomainData(mapYearLevelToDomain);
            processGraphDataByIndex(0, mapYearLevelToDomain);
          }
        });
    } else {
      // ==================== 
      // Stacked Line Chart
      // ====================
      let tempTabs = studentStore.schoolDashboardDomainData.map(data => data.yearLevel)
        .sort(function (a, b) {
          return a - b;
        });
      setYearLevelTabs(tempTabs);

      let mapYearLevelToDomain = studentStore.schoolDashboardDomainData
        .sort(function (a, b) {
          return a.yearLevel - b.yearLevel;
        });
      setMapYearLevelToDomainData(mapYearLevelToDomain);
      processGraphDataByIndex(0, mapYearLevelToDomain);
    }

    if (studentStore.schoolNaplanData.length == 0) {
      studentStore.getDashboardSchoolNaplanData()
        .then((res) => {
          // ==================== 
          // Radar Graph
          // ====================
          let tempNaplanIndicator = [];
          if (res.length > 0) {
            setNaplanPrepareData(res);
            setYearList([
              ... new Set(res.map(data => data.year))
            ]);
            setYearLevelList([
              ... new Set(res.map(data => data.yearLevel))
            ]);
            // Initial default option for two radar filter dropdowns
            setYearInput(res[0].year);
            setYearLevelInput(res[0].yearLevel);

            for (var data of res[0].data) {
              tempNaplanIndicator.push({
                name: data.strandName,
                max: 10
              })
            }

            // Reverse the array so that grammar & punctutation with be the first one to avoid overflow
            setNaplanIndicator(tempNaplanIndicator.reverse());
            processRadarData(res, 'all');
            processRadarData(res, 'byYear', res[0].year);
            processRadarData(res, 'byYearLevel', res[0].yearLevel);
          }
        });
    } else {
      // ==================== 
      // Radar Graph
      // ====================
      let tempNaplanIndicator = [];
      if (studentStore.schoolNaplanData.length > 0) {
        setNaplanPrepareData(studentStore.schoolNaplanData);
        setYearList([
          ... new Set(studentStore.schoolNaplanData.map(data => data.year))
        ]);
        setYearLevelList([
          ... new Set(studentStore.schoolNaplanData.map(data => data.yearLevel))
        ]);
        // Initial default option for two radar filter dropdowns
        setYearInput(studentStore.schoolNaplanData[0].year);
        setYearLevelInput(studentStore.schoolNaplanData[0].yearLevel);

        for (var data of studentStore.schoolNaplanData[0].data) {
          tempNaplanIndicator.push({
            name: data.strandName,
            max: 10
          })
        }

        // Reverse the array so that grammar & punctutation with be the first one to avoid overflow
        setNaplanIndicator(tempNaplanIndicator.reverse());
        processRadarData(studentStore.schoolNaplanData, 'all');
        processRadarData(studentStore.schoolNaplanData, 'byYear', studentStore.schoolNaplanData[0].year);
        processRadarData(studentStore.schoolNaplanData, 'byYearLevel', studentStore.schoolNaplanData[0].yearLevel);
      }
    }

    if (!studentStore.studentStaffOverviewData) {
      if (filterStore.academicYearList.length > 0) {
        if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
        studentStore.getDashboardData(filterStore.currentSemester);
      } else {
        filterStore.getSemesterDateList().then(() => {
          filterStore.getCurrentSemester();
          studentStore.getDashboardData(filterStore.currentSemester);
        })
      }
    }


  }, [])

  const processGraphDataByIndex = (index: number, mapData: any) => {
    let tempXAxis = [];
    let tempFinalData = []
    // ===========
    // Order Legends
    // ===========
    let tempLegend = mapData[index].domainMarkOverviewData
      .filter((data) => data.label != PRIORITY_SUBJECT_1 && data.label != PRIORITY_SUBJECT_2).map(data => data.label);
    tempLegend.unshift(PRIORITY_SUBJECT_2);
    tempLegend.unshift(PRIORITY_SUBJECT_1);

    if (initialLegend) {
      onChangeSelectedLegend(tempLegend, false);
      setInitialLegend(false);
    }
    setLegend(tempLegend);

    if (mapData[index].domainMarkOverviewData.length > 0) {
      for (var data of mapData[index].domainMarkOverviewData[index].data)
        tempXAxis.push(data.year + ' S' + data.semester);
    }
    setXAxis(tempXAxis);

    for (var data of mapData[index].domainMarkOverviewData) {
      let tempValue = [];
      for (var axisData of data.data) tempValue.push(+axisData.data);

      if (data.label != PRIORITY_SUBJECT_1 && data.label != PRIORITY_SUBJECT_2) {
        tempFinalData.push({
          name: data.label,
          type: 'line',
          data: tempValue,
          smooth: true,
        })
      } else {
        tempFinalData.unshift({
          name: data.label,
          type: 'line',
          data: tempValue,
          smooth: true,
        })
      }
    }

    for (var data of tempFinalData) {
      if (data.data[data.data.length - 1] == 0)
        data.data.pop();
    }

    console.log(mapData[index].domainMarkOverviewData, tempFinalData, tempLegend);
    setPerformanceData(tempFinalData);
  }

  const onChangeSelectedLegend = (tempLegend: any, showAllLegend: boolean) => {
    let selected = {};

    let index = 0;
    for (var legend of tempLegend) {
      if (showAllLegend) {
        selected[legend] = true;
      } else {
        if (legend != PRIORITY_SUBJECT_1 && legend != PRIORITY_SUBJECT_2) selected[legend] = false;
        else selected[legend] = true;
      }
      index++;
    }
    setSelectedLegend(selected);
    console.log(selected);

  }

  const processRadarData = async (mapData: any, mode: 'all' | 'byYear' | 'byYearLevel', input?: string) => {
    let tempNaplanLegend = [];
    let tempFinalNaplanResult = [];
    for (var data of mapData) {
      if (mode == 'byYear' && data.year != input) continue;
      else if (mode == 'byYearLevel' && data.yearLevel != input) continue;
      else
        tempNaplanLegend.push(data.year + ' Y' + data.yearLevel);

      let tempNaplanValue = [];
      for (var value of data.data) {
        tempNaplanValue.unshift(value.schoolResultBand);
      }
      tempFinalNaplanResult.push({
        value: tempNaplanValue,
        name: data.year + ' Y' + data.yearLevel
      });
    }
    console.log(tempFinalNaplanResult);

    if (mode == 'all') {
      setNaplanLegend(tempNaplanLegend);
      setNaplanData(tempFinalNaplanResult);
    } else if (mode == 'byYear') {
      setNaplanByYearLegend(tempNaplanLegend);
      setNaplanByYearData(tempFinalNaplanResult);
    } else {
      setNaplanByYearLevelLegend(tempNaplanLegend);
      setNaplanByYearLevelData(tempFinalNaplanResult);
    }
  }

  const handleTabChange = (event, newValue) => {
    setPerformanceTab(newValue);
    processGraphDataByIndex(newValue, mapYearLevelToDomainData);
  };

  const onChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selected = event.target.value as number;
    setYearInput(selected);
    processRadarData(naplanPrepareData, 'byYear', selected.toString());
  }

  const onChangeYearLevel = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selected = event.target.value as number;
    setYearLevelInput(selected);
    processRadarData(naplanPrepareData, 'byYearLevel', selected.toString());
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showFilter={false}
          showYearSemTerm={false}
          showClassCode={false}
          showDomain={false}
          showStaffList={false}
          showNewsArea={true}
          showSeachStudent={true}
        >
          <title>{t('SCHOOL_DASHBOARD')}</title>
          <div className="flex flex-col mx-4" style={{ minHeight: '100vh' }}>

            <VMCard
              className="pb-2 mt-2"
              titleComponent={
                <div className="text-barPrimary text-lg font-bold p-4 pb-0 uppercase">
                  {t('SCHOOL_PROFILE')}
                </div>
              }
            >
              <div className="grid grid-cols-2 xl:grid-cols-3 pb-4">
                {
                  studentStore.loading || filterStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS')}: {studentStore.dashboardData.totalStudentNumber}
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_TEACHERS')}: {studentStore.dashboardData.totalTeacherNumber}
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STAFFS')}: {studentStore.dashboardData.totalStaffNumber}
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_12')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "12")[0].number
                        }
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_11')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "11")[0].number
                        }
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_10')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "10")[0].number
                        }
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_9')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "9")[0].number
                        }
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_8')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "8")['0'].number
                        }
                      </div>
                      <div className={fieldStyle}>
                        {t('TOTAL_NUM_STUDENTS_7')}: {
                          (!studentStore.dashboardData.studentNumberByYearLevel || studentStore.dashboardData.studentNumberByYearLevel.length == 0) ?
                            '0' :
                            studentStore.dashboardData.studentNumberByYearLevel.filter((s) => s.yearLevel == "7")[0].number
                        }
                      </div>
                    </>
                }

              </div>
            </VMCard>

            <VMCard
              className="my-4"
              titleComponent={
                <div className="text-barPrimary text-lg font-bold px-4 my-4 uppercase">
                  {t('SCHOOL_YEARLY_PERFORMANCE')}
                </div>
              }
            >

              {
                studentStore.loadingYearlyPerformance || filterStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <>
                    <hr />
                    <Tabs
                      TabIndicatorProps={{ style: { display: "none" } }}
                      value={performanceTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {
                        yearLevelTabs && yearLevelTabs.map((data, index) => (
                          <Tab
                            value={index}
                            key={'dashboard_year_level_tab_' + index}
                            label={`Year ${data}`}
                            {...performanceTabProps(index, index === performanceTab)}
                          />
                        ))
                      }
                    </Tabs>
                    <hr />
                    <div className="text-right">
                      <Button
                        className={`rounded-lg text-white bg-barPrimary text-sm m-4`}
                        component="span"
                        onClick={() => {
                          if (showAllLegend) onChangeSelectedLegend(legend, false);
                          else onChangeSelectedLegend(legend, true);
                          setShowAllLegend(!showAllLegend);
                        }}
                      >
                        {showAllLegend ? t('SHOW_ONLY_ENGLISH_MATH') : t('SHOW_ALL_SUBJECTS')}
                      </Button>
                    </div>
                    {
                      yearLevelTabs && yearLevelTabs.map((data, index) => (
                        <TabPanel key={'tab_panel_line_race_' + index} value={performanceTab} paddingValue={0} index={index}>
                          {
                            <IESLineRace
                              dataOptions={{
                                legend: legend,
                                selectedLegend: selectedLegend,
                                xAxis: xAxis,
                                yAxisName: "%",
                                dataSeries: performanceData,
                              }}
                            />
                          }
                        </TabPanel>
                      ))
                    }
                  </>
              }
            </VMCard>

            <VMCard
              className='mb-4'
              titleComponent={
                <div className="text-barPrimary text-lg font-bold px-4 my-4 uppercase">
                  {t('SCHOOL_NAPLAN_OVERVIEW_BY_YEAR_YEAR_LEVEL')}
                </div>
              }
            >
              {
                studentStore.loadingSDashNaplan || filterStore.loading ? <div className="col-span-3 w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <>
                    {/* <IESRadar
                  dataOptions={{
                    radius: '65%',
                    legend: naplanLegend,
                    indicator: naplanIndicator,
                    name: 'Naplan Performance',
                    series: naplanData
                  }}
                />
                <hr className="mx-10 mb-10" /> */}
                    <div className="grid grid-cols-2 px-4 mt-8">
                      <div>
                        <div className="mb-6 text-center">
                          <span className="text-barPrimary mr-2">{t('IN_YEAR')}</span>
                          {
                            yearList &&
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className='p-0'
                              style={{ width: 100 }}
                              value={yearInput}
                              onChange={onChangeYear}
                            >
                              {
                                yearList.map((ay, index) => (
                                  <MenuItem key={`year_list_${ay.id}_${index}`} value={ay}>
                                    {+ay}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                          }
                        </div>

                        <IESRadar
                          dataOptions={{
                            radius: '55%',
                            legend: naplanByYearLegend,
                            indicator: naplanIndicator,
                            name: 'Naplan Performance',
                            series: naplanByYearData
                          }}
                        />
                      </div>

                      <div>
                        <div className="mb-6 text-center">
                          <span className="text-barPrimary mr-2">{t('FOR_YEAR_LEVEL')}</span>
                          {
                            yearLevelList &&
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className={classes.root}
                              style={{ width: 70 }}
                              value={yearLevelInput}
                              onChange={onChangeYearLevel}
                            >
                              {
                                yearLevelList.map((ay, index) => (
                                  <MenuItem key={`year_list_${ay.id}_${index}`} value={ay}>
                                    {+ay}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                          }
                        </div>

                        <IESRadar
                          dataOptions={{
                            radius: '55%',
                            legend: naplanByYearLevelLegend,
                            indicator: naplanIndicator,
                            name: 'Naplan Performance',
                            series: naplanByYearLevelData
                          }}
                        />
                      </div>

                    </div>

                  </>
              }

            </VMCard>
          </div>
        </Layout>
      }
    </>
  )
})

export default Dashboard;

