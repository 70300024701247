import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { CHART_HEIGHT, CHART_WIDTH } from '../../constants/style';

const IESLineRace = observer(({ dataOptions }) => {
    const [selectedLegend, setSelectedLegend] = useState<any>();

    useEffect(() => {
        // loop through legend inputs and set subjects: english/maths to display true only
        // if (dataOptions.legend) {
        //     let selected = {};
        //     let index = 0;
        //     for (var legend of dataOptions.legend) {
        //         if (legend != 'English' && legend != 'Mathematics') selected[legend] = false;
        //         index++;
        //     }
        //     setSelectedLegend(selected);
        // }
        setSelectedLegend(dataOptions.selectedLegend);
        option.legend.selected = selectedLegend;
    }, [dataOptions]);

    const option = {
        tooltip: dataOptions.yAxisShow != null && !dataOptions.yAxisShow ? {
            trigger: 'axis',
            formatter: function (param) {
                let result = param[0].name + "<br />";
                param.map((value: any) => {
                    result += `<div style='text-align:left'><span style='display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${value.color};'></span>
                    <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${value.seriesName}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${value.data >= 3 ? "Always" :
                        value.data >= 2 ? "Usually" :
                            value.data >= 1 ? "Sometimes" :
                                value.data >= 0 ? "Attention Required" : value.data}</span></div>`;
                    // result += "<br /><br />";
                })
                return result;
            }
        } : { trigger: 'axis' },
        legend: {
            data: dataOptions.legend,
            selectorLabel: { show: false },
            selected: selectedLegend
        },
        grid: {
            top: dataOptions.top ? dataOptions.top : '30%',
            left: dataOptions.left ? dataOptions.left : '3%',
            right: '4%',
            bottom: '5%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dataOptions.xAxis,
        },
        yAxis: {
            name: dataOptions.yAxisName ? dataOptions.yAxisName : "",
            type: 'value',
            min: dataOptions.min && dataOptions.min,
            max: dataOptions.max && dataOptions.max,
            axisLabel: dataOptions.yAxisShow != null && !dataOptions.yAxisShow ? {
                formatter: function (param) {
                    return param == 3 ? "Always" :
                        param == 2 ? "Usually" :
                            param == 1 ? "Sometimes" :
                                param == 0 ? "Attention Required" : "";
                }
            } : { show: true },
        },
        series: dataOptions.dataSeries
    };
    return (
        <>
            {
                selectedLegend &&
                <ReactEcharts
                    style={{
                        height: '350px',
                        width: CHART_WIDTH,
                    }}
                    option={option}
                />
            }
        </>

    );
})

export default IESLineRace;